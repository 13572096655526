import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.onScroll();
        this.smoothScroll();
    }

    onScroll() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 85%",
                onEnter: () => {
                    el.classList.add("-reveal");
                },
            });
        });

        const navScroll = () => {
            const headerNav = document.querySelector(".js-headernav");

            window.addEventListener("scroll", () => {
                if (window.scrollY > 100) {
                    headerNav.setAttribute("data-active", "1");
                } else {
                    headerNav.setAttribute("data-active", "0");
                }
            });
        };

        navScroll();
    }

    smoothScroll() {
        const triggers = [...document.querySelectorAll(".js-smoothscroll")];
        const pagetop = [...document.querySelectorAll(".js-pagetop")];

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const hash = el.getAttribute("href");
                const offset = window.innerWidth <= 768 ? window.innerWidth * (0.143 + 0.065104166667) : 100;
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: "circ.inOut" });
            });
        });

        pagetop.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: 0 }, ease: "circ.inOut" });
            });
        });
    }
}
