//common funcs
import { screenHeight } from "./common/function/screenheight.es6";
import { gnav } from "./common/function/gnav.es6";

//common classes
import Modal from "./common/classes/modal.es6";
import ScrollFunc from "./common/classes/scrollfunc.es6";

class Common {
    constructor() {
        this.modal = new Modal();
        this.scrollfunc = new ScrollFunc();
        this.init();
    }

    init() {
        //common funcs
        screenHeight();
        gnav();
    }

    resetModalEvents() {
        this.modal.resetEvent();
    }
}

const common = new Common();
